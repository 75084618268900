import * as React from 'react';
import '../styles/Home.css';
import {useLoaderData, useNavigate} from "react-router-dom";
import {getOtpCode, getProfileInfo, sendOtpCode} from "../api/api";
import {Store} from "../stores/cards";
import ErrorPage from "./ErrorPage";
import {useEffect, useRef, useState} from "react";
import {ProfileForm} from "../forms/ProfileForm";
import {CODE_LENGTH, CodeForm} from "../forms/CodeForm";

const CODE_EXPIRED_TIME = 90000;
const CODE_REQUEST_AGAIN_TIME = 120000;
const CODE_ATTEMPTS_EXCEEDED_TIME = 600000;

export async function profileConfirmationLoader(): Promise<{
    search?: string,
    token?: string,
    error?: boolean,
    noAccess?: boolean
}> {
    const url = new URL(window.location.href);
    let token = url.searchParams.get('client_token');
    if (!token) {
        const localToken = localStorage.getItem('client_token');
        if (!!localToken) {
            window.location.href = `/?client_token=${localToken}`;
            return {
                error: false
            };
        } else {
            return {
                error: false,
                noAccess: true
            };
        }
    }
    if (!localStorage.getItem('client_token')) {
        localStorage.setItem('client_token', token);
    }
    if (localStorage.getItem('otp_code_confirmation_datetime')) {
        window.location.href = `/${url.search}`;
        return {
            error: false
        };
    }
    if (!localStorage.getItem('otp_code_attempts_exceeded_date') && localStorage.getItem('otp_code_send_datetime')
        && new Date().getTime() - Number(localStorage.getItem('otp_code_send_datetime')!) > CODE_EXPIRED_TIME) {
        localStorage.removeItem('otp_code_send_datetime');
    }
    if (!Store.getInstance().getProfile()) {
        const responseProfile = await getProfileInfo(token);
        if (!responseProfile || (responseProfile && !responseProfile.result) || (responseProfile && responseProfile.error)) {
            return {
                error: true
            };
        }
    }
    window.scrollTo({top: 0, behavior: 'smooth'});
    return { search: url.search, token, error: false };
}

function ProfileConfirmationPage() {
    const navigate = useNavigate();
    const loaderData = useLoaderData();
    const [code, setCode] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [sentOtpCodeDatetime, setSentOtpCodeDatetime] = useState<number>(
        localStorage.getItem('otp_code_send_datetime') ? Number(localStorage.getItem('otp_code_send_datetime'))
            : 0);
    const [expiredTime, setExpiredTime] = useState<Date | boolean>(false);
    const [isCanRequestCodeAgain, setIsCanRequestCodeAgain] = useState<boolean>(!!localStorage.getItem('otp_code_attempts_exceeded_date') || false);
    const [expiredTimeRequestCodeAgain, setExpiredTimeRequestCodeAgain] = useState<Date | boolean>(false);
    const [timestampExpiredTimeRequestCodeAgain, setTimestampExpiredTimeRequestCodeAgain] = useState<number>( 0);
    const timer = useRef<NodeJS.Timer | number | null>(null);

    useEffect(() => {
        if (isCanRequestCodeAgain) {
            const sentDatetime = new Date(localStorage.getItem('otp_code_attempts_exceeded_date')!);
            const diff = sentDatetime.getTime() + CODE_ATTEMPTS_EXCEEDED_TIME - new Date().getTime();
            const date = new Date();
            date.setTime(diff);
            setExpiredTimeRequestCodeAgain(date);
            timer.current = setInterval(() => {
                const sentDatetime = new Date(localStorage.getItem('otp_code_attempts_exceeded_date')!);
                const diff = sentDatetime.getTime() + CODE_ATTEMPTS_EXCEEDED_TIME - new Date().getTime();
                const date = new Date();
                date.setTime(diff);
                setExpiredTimeRequestCodeAgain(date);
            }, 1000);
        }
        return () => {
            if (timer.current) {
                clearInterval(timer.current);
                timer.current = null;
            }
        }
    }, []);

    useEffect(() => {
        if (sentOtpCodeDatetime && !isCanRequestCodeAgain) {
            if (timer.current) {
                clearInterval(timer.current);
                timer.current = null;
            }
            const diff = sentOtpCodeDatetime + CODE_EXPIRED_TIME - new Date().getTime();
            const date = new Date();
            date.setTime(diff);
            setExpiredTime(date);
            timer.current = setInterval(() => {
                const diff = sentOtpCodeDatetime + CODE_EXPIRED_TIME - new Date().getTime();
                const date = new Date();
                date.setTime(diff);
                setExpiredTime(date);
            }, 1000);
        }
    }, [sentOtpCodeDatetime]);

    useEffect(() => {
        if (!!expiredTime && typeof expiredTime === 'object' && expiredTime.getTime() < 1000) {
            if (timer.current) {
                clearInterval(timer.current);
                timer.current = null;
            }
            setExpiredTime(false);
            setIsCanRequestCodeAgain(true);
            const diff = sentOtpCodeDatetime + CODE_REQUEST_AGAIN_TIME - new Date().getTime();
            const date = new Date();
            date.setTime(diff);
            setExpiredTimeRequestCodeAgain(date);
            timer.current = setInterval(() => {
                const diff = sentOtpCodeDatetime + CODE_REQUEST_AGAIN_TIME + timestampExpiredTimeRequestCodeAgain - new Date().getTime();
                const date = new Date();
                date.setTime(diff);
                setExpiredTimeRequestCodeAgain(date);
            }, 1000);
        }
    }, [expiredTime]);

    useEffect(() => {
        if (!!expiredTimeRequestCodeAgain && typeof expiredTimeRequestCodeAgain === 'object' && expiredTimeRequestCodeAgain.getTime() < 1000) {
            setExpiredTimeRequestCodeAgain(false);
            if (timer.current) {
                clearInterval(timer.current);
                timer.current = null;
            }
        }
    }, [expiredTimeRequestCodeAgain]);

    const isError = !!(loaderData && (loaderData as any).error);
    if (isError) {
        return ErrorPage();
    }
    const isNoAccess = !!(loaderData && (loaderData as any).noAccess);
    if (isNoAccess) {
        return ErrorPage('У вас нет доступа к данной странице!');
    }
    const token = loaderData && (loaderData as any).token;
    const search = loaderData && (loaderData as any).search;
    const profile = Store.getInstance().getProfile();

    async function getCode() {
        const res = await getOtpCode(token);
        if (timestampExpiredTimeRequestCodeAgain > 0) {
            setTimestampExpiredTimeRequestCodeAgain(0);
        }
        if (localStorage.getItem('otp_code_attempts_exceeded_date')) {
            localStorage.removeItem('otp_code_attempts_exceeded_date');
        }
        if (res && !res.error) {
            const datetime = new Date().getTime();
            setSentOtpCodeDatetime(datetime);
            localStorage.setItem('otp_code_send_datetime', datetime.toString());
            if (isCanRequestCodeAgain) {
                setIsCanRequestCodeAgain(false);
            }
            return;
        } else {
            alert(res && res.error ? res.error : 'Неизвестная ошибка');
            return;
        }
    }

    async function updateCode(newCode: string) {
        setCode(newCode);
        if (error) {
            setError('');
        }
        if (newCode.length === CODE_LENGTH) {
            const res = await sendOtpCode(token, newCode);
            if (res && !res.error && res.result) {
                localStorage.setItem('otp_code_confirmation_datetime', new Date().toISOString());
                localStorage.removeItem('otp_code_send_datetime');
                localStorage.removeItem('otp_attempts_exceeded');
                navigate(`/registration${search}`);
            } else {
                if (res.error_key && res.error_key === 'otp_attempts_exceeded') {
                    setTimestampExpiredTimeRequestCodeAgain(CODE_ATTEMPTS_EXCEEDED_TIME - CODE_REQUEST_AGAIN_TIME);
                    localStorage.setItem('otp_code_attempts_exceeded_date', new Date().toISOString());
                    const date = new Date();
                    date.setTime(0);
                    setExpiredTime(date);
                }
                setCode('');
                setError(res && res.error ? res.error : 'Неизвестная ошибка');
            }
        }
    }

    return (
        <div className='foreign-card-container'>
            {sentOtpCodeDatetime ? <CodeForm
                code={code}
                onUpdateCode={updateCode}
                onRequestCodeAgain={getCode}
                error={error}
                expiredTime={expiredTime}
                isCanRequestCodeAgain={isCanRequestCodeAgain}
                expiredTimeRequestCodeAgain={expiredTimeRequestCodeAgain}
            />
                : <ProfileForm
                    profile={profile!}
                    onSubmit={async () => await getCode()}
                />}
        </div>
    );
}

export default ProfileConfirmationPage;
