import * as React from "react";
import {Profile} from "../models/Profile";
import '../styles/Home.css';
import {useState} from "react";

export function ProfileForm(props: {
    profile: Profile,
    onSubmit: () => any,
}) {
    const {profile, onSubmit} = props;
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [isAgreement, setIsAgreement] = useState<boolean>(false);

    return <form>
        <h2>
            Ваши данные
        </h2>
        <label>
            Фамилия
        </label>
        <input
            disabled
            value={profile?.last_name}
        />
        <label>
            Имя
        </label>
        <input
            disabled
            value={profile?.first_name}
        />
        <label>
            Номер телефона
        </label>
        <input
            disabled
            value={profile?.phone}
        />
        <div className='submit_button__container'>
            <div
                className='submit_button__container__agreement'
                onClick={() => setIsAgreement(prevState => !prevState)}
            >
                <input
                    type={'checkbox'}
                    checked={isAgreement}
                    onChange={() => ''}
                />
                <div>
                    Нажимая на кнопку 'Отправить заявку', я даю{' '}
                    <a href='/oferta?key=otp' target='_blank' rel='noreferrer'>
                        Согласие на обработку персональных данных, принимаю условия Публичного договора-оферты на
                        оказание информационно-консультативных услуг
                    </a>
                </div>
            </div>
            <button
                type='submit'
                disabled={!isAgreement}
                id='submit_button'
                onClick={async (event) => {
                    event.preventDefault();
                    if (!isSubmitted) {
                        setIsSubmitted(true);
                        await onSubmit();
                        setIsSubmitted(false);
                    }
                }}
            >
                Отправить СМС
            </button>
        </div>
    </form>;
}
