import {Profile} from "../models/Profile";

export class Store {
    private static instance: Store;
    private _cards: any = null;
    private _bankId: any = null;
    private _mobileOperators: any = null;
    private _profile: Profile | null = null;

    private constructor(cards?: any) {
        if (cards) {
            this._cards = cards;
        }
    }

    public static getInstance(cards?: any): Store {
        if (!Store.instance) {
            Store.instance = new Store(cards);
        }
        return Store.instance;
    }

    public getCurrentCards(): any {
        return this._cards;
    }

    public getCurrentBankId(): any {
        return this._bankId;
    }

    public getMobileOperators(): any {
        return this._mobileOperators;
    }

    public getProfile(): Profile | null {
        return this._profile;
    }

    public setCards(cards: any): any {
        this._cards = cards;
    }

    public setBankId(id: number): any {
        this._bankId = id;
    }

    public setMobileOperators(mobileOperators: string[]): any {
        this._mobileOperators = mobileOperators;
    }

    public setProfile(profile: Profile): any {
        this._profile = profile;
    }
}
