import * as React from 'react';
import '../styles/Home.css';
import {useLoaderData, useNavigate} from "react-router-dom";
import {auth, getCards} from "../api/api";
import sendErrorToBot from '../api/sendError';
import {Store} from "../stores/cards";
import ErrorPage from "./ErrorPage";

export async function homeLoader() {
    const url = new URL(window.location.href);
    let token = url.searchParams.get('client_token');
    const returnUrl = url.searchParams.get('return_url');
    if (token) {
        localStorage.setItem('client_token', token!);
        if (returnUrl) {
            localStorage.setItem('return_url', returnUrl);
        }
    } else {
        const localToken = localStorage.getItem('client_token');
        return !!localToken
            ? window.location.href = `/?client_token=${localToken}`
            : { noAccess: true };
    }
    const res = await auth({token});
    if (!res || res.error) {
        sendErrorToBot('🟡FOREIGN CARD REGISTRATION',
            url.href + `${res && res.error ? `\nError: ${JSON.stringify(res.error)}` : ''}`
            + `${navigator && navigator.userAgent ? `\n📱Device: ${navigator.userAgent}` : ''}`);
        return { error: true };
    }
    const CardsInst = Store.getInstance();
    let cards = CardsInst.getCurrentCards();
    let bankId = CardsInst.getCurrentBankId();
    if (!cards) {
        const responseCards = await getCards();
        if (!responseCards || !responseCards.result || !responseCards.result.cards
            || responseCards.result.cards.length === 0) {
            sendErrorToBot('🟡FOREIGN CARD REGISTRATION CARDS',
                window.location.href + `${responseCards && responseCards.error ? `\nError: ${JSON.stringify(responseCards.error)}` : ''}`
                + `${responseCards && responseCards.result ? `\nResult: ${JSON.stringify(responseCards.result)}` : ''}`
                + `${navigator && navigator.userAgent ? `\n📱Device: ${navigator.userAgent}` : ''}`);
            return { error: true };
        }
        cards = responseCards.result.cards;
        bankId = responseCards.result.id;
        if (!bankId) {
            sendErrorToBot('🟡FOREIGN CARD REGISTRATION CARDS',
                window.location.href + '\nНЕ ПРИШЕЛ BANK ID'
                + `${responseCards && responseCards.error ? `\nError: ${JSON.stringify(responseCards.error)}` : ''}`
                + `${responseCards && responseCards.result ? `\nResult: ${JSON.stringify(responseCards.result)}` : ''}`
                + `${navigator && navigator.userAgent ? `\n📱Device: ${navigator.userAgent}` : ''}`);
            return { error: true };
        }
        CardsInst.setCards(cards);
        CardsInst.setBankId(bankId);
    }
    return { cards, token };
}

function HomePage() {
    const navigate = useNavigate();
    const loaderData = useLoaderData();
    const isError = !!(loaderData && (loaderData as any).error);
    if (isError) {
        return ErrorPage();
    }
    const isNoAccess = !!(loaderData && (loaderData as any).noAccess);
    if (isNoAccess) {
        return ErrorPage('У вас нет доступа к данной странице!');
    }
    const token = loaderData && (loaderData as any).token;

    return (
        <div className='foreign-card-container'>
            <h2>
                Информация о картах
            </h2>
            {loaderData && (loaderData as any).cards && (loaderData as any).cards.map((card: any, i: number) => card.id && <div
                key={i}
                className='foreign-card-container__country-block'
            >
                <div className='foreign-card-container__info-block'>
                    <h4 className='card-name'>{card.name}</h4>
                    {card.cost && <h4 className='card-cost'>
                        Стоимость <br/>оформления: <br/>{card.cost}
                    </h4>}
                </div>
                <div>
                    {card.card_type && `Вид карты: ${card.card_type === 'debit' ? 'дебетовая' : card.card_type}`}
                </div>
                <div>
                    {card.currencies && `Валюта карты: ${card.currencies.reduce((str: string, cur: { key: string, name_ru: string }) => str + '/' + cur.key.toUpperCase(), '').replace('/', '')}`}
                </div>
                <div>
                    {card.issue_fee && `Выпуск карты: ${card.issue_fee}`}
                </div>
                {card.maintenance_fee && card.maintenance_fee.length&& card.maintenance_fee.length > 0
                    ? card.maintenance_fee.map((fee: any, i: number) =>
                        <div key={i}>
                            {`Обслуживание карты: ${fee}`}
                        </div>)
                    : ''}
                <div>
                    {card.sms_notification_fee && `СМС-информирование: ${card.sms_notification_fee}`}
                </div>
                <div>
                    {card.cash_withdrawal_fee && `Комиссия за снятие наличных: ${card.cash_withdrawal_fee}`}
                </div>
                <div>
                    {card.minimum_balance && `Неснижаемый остаток: ${card.minimum_balance}`}
                </div>
                <div>
                    {card.daily_limit && `Общий суточный лимит по операциям: ${card.daily_limit}`}
                </div>
                <div>
                    {card.daily_operations_limit && `Ограничения по расходным операциям: ${card.daily_operations_limit}`}
                </div>
                <div>
                    {card.additional_features && card.additional_features.length&& card.additional_features.length > 0
                        ? `Дополнительные возможности: ${card.additional_features.reduce((prev: string, cur: string) => prev + ', ' + cur, '').slice(2)}` : ''}
                </div>
                <div>
                    {card.top_up_options && `Пополнение: ${card.top_up_options}`}
                </div>
            </div>)}
            <div className='submit_button__container'>
                <button
                    type='submit'
                    id='submit_button'
                    onClick={() => navigate(`/registration?client_token=${token}`)}
                >
                    Заполнить заявку
                </button>
            </div>
        </div>
    );
}

export default HomePage;
