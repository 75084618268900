import * as React from "react";
import '../styles/Home.css';
import '../styles/CodePage.css';
import {toLocaleTimeString} from "../utils/utils";
import {useState} from "react";

export const CODE_LENGTH = 4;

export function CodeForm(props: {
    code: string,
    onUpdateCode: (newCode: string) => any,
    onRequestCodeAgain: () => any,
    error: string,
    expiredTime: Date | boolean,
    isCanRequestCodeAgain: boolean,
    expiredTimeRequestCodeAgain: Date | boolean,
}) {
    const {code, error, expiredTime, isCanRequestCodeAgain, expiredTimeRequestCodeAgain,
        onUpdateCode, onRequestCodeAgain} = props;
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    return <div className='authorization-container'>
        <div className='authorization'>
            <div className='auth-body'>
                <div className='auth-body-title'>
                    Введите код из смс
                </div>
                <div className='auth-body-code'>
                    <input
                        autoComplete='one-time-code'
                        autoFocus={true}
                        type='tel'
                        name='code'
                        value=''
                        disabled={isCanRequestCodeAgain}
                        onKeyDown={(e) => e.keyCode === 8 || e.keyCode === 46
                            ? onUpdateCode(code.slice(0, code.length - 1))
                            : {}}
                        onChange={(e) => (code.length < CODE_LENGTH)
                            ? onUpdateCode(code + e.target.value)
                            : {}}
                    />
                    <div className='auth-body-input'>
                        {new Array(CODE_LENGTH).fill(0).map((v, i) => {
                            return (
                                <span key={i} className={`${i < code.length ? 'black' : ''}`}/>
                            );
                        })}
                    </div>
                </div>
                <div>
                    {!!expiredTime && typeof expiredTime === 'object'
                        ? `Код истечет через ${toLocaleTimeString(expiredTime, true)}`
                        : 'Код истек'
                    }
                </div>
            </div>
            <div className='auth-message'>
                <div className={`auth-error ${error ? 'show' : ''}`}
                     dangerouslySetInnerHTML={{__html: error}}/>
            </div>
        </div>
        {isCanRequestCodeAgain && <div className='submit_button__container animation'>
            {!!expiredTimeRequestCodeAgain && typeof expiredTimeRequestCodeAgain === 'object' && <div
                className='submit_button__container__agreement animation'
            >
                <div>
                    Вы сможете переотправить СМС через {toLocaleTimeString(expiredTimeRequestCodeAgain, true)}
                </div>
            </div>}
            <button
                type='submit'
                disabled={!!expiredTimeRequestCodeAgain}
                id='submit_button'
                onClick={async (event) => {
                    event.preventDefault();
                    if (!isSubmitted) {
                        setIsSubmitted(true);
                        await onRequestCodeAgain();
                        setIsSubmitted(false);
                    }
                }}
            >
                Отправить СМС
            </button>
        </div>}
    </div>;
}
