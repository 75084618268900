export const getShortAddress = (address: any) => {
    let formattedAddress = address.formatted_address;
    if (address.address_components) {
        const streetNumber = address.address_components.find((component: any) => component.types.includes('street_number'));
        const route = address.address_components.find((component: any) => component.types.includes('route'));
        const subpremise = address.address_components.find((component: any) => component.types.includes('subpremise'));
        if (route) {
            return `${route.short_name || route.long_name}${streetNumber ? ` ${streetNumber.short_name}` || ` ${streetNumber.long_name}` : ''}${subpremise ? `, ${subpremise.short_name || subpremise.long_name}` : ''}`;
        }
        const postalCode = address.address_components.find((component: any) => component.types.includes('postal_code'));
        if (!!postalCode && postalCode !== -1) {
            return formattedAddress.replace(`, ${postalCode.short_name || postalCode.long_name}`, '');
        }
        return address.address_components[0].short_name || address.address_components[0].long_name;
    }
    return formattedAddress;
};

export const getFullAddress = (address: any) => {
    let formattedAddress = address.formatted_address;
    if (address.address_components) {
        let fullAddress = '';
        const city = address.address_components.find((component: any) => component.types.includes('locality'));
        if (city) {
            if (city.short_name !== 'СПБ' && city.short_name !== 'Москва') {
                fullAddress += `${city.short_name || city.long_name}`;
            }
        } else {
            const cityReserved = address.address_components.find((component: any) => component.types.includes('administrative_area_level_2'));
            fullAddress += `${cityReserved.short_name || cityReserved.long_name}`;
        }
        const region = address.address_components.find((component: any) => component.types.includes('administrative_area_level_1'));
        if (region) {
            if (fullAddress) {
                fullAddress += ', ';
            }
            fullAddress += `${region.long_name || region.short_name}`;
        }
        const country = address.address_components.find((component: any) => component.types.includes('country'));
        if (region) {
            if (fullAddress) {
                fullAddress += ', ';
            }
            fullAddress += `${country.long_name || country.short_name}`;
        }
        return fullAddress || address.address_components[0].short_name || address.address_components[0].long_name;
    }
    return formattedAddress;
};

export function isEqualsNotEmptyString(text: string, value: any) {
    return !!value && value.length > 0 && !!text && text.length > 0
        ? text === value.toLowerCase()
        : false;
}
