import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter, RouterProvider
} from "react-router-dom";
import Home, {homeLoader} from "./pages/Home";
import './styles/index.css';
import ErrorPage from "./pages/ErrorPage";
import RegistrationPage, {registrationLoader} from "./pages/RegistrationPage";
import OfertaPage, {ofertaLoader} from "./pages/OfertaPage";
import ProfileConfirmationPage, {profileConfirmationLoader} from "./pages/ProfileConfirmationPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        loader: homeLoader,
        errorElement: ErrorPage()
    },
    {
        path: "/confirmation",
        element: <ProfileConfirmationPage/>,
        loader: profileConfirmationLoader,
        errorElement: ErrorPage()
    },
    {
        path: "/registration",
        element: <RegistrationPage/>,
        loader: registrationLoader,
        errorElement: ErrorPage()
    },
    {
        path: "/oferta",
        element: <OfertaPage/>,
        loader: ofertaLoader,
        errorElement: ErrorPage()
    },
    {
        path: "*",
        element: ErrorPage('Страница не найдена!'),
        errorElement: ErrorPage()
    }]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <RouterProvider router={router} />
);
