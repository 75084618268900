import * as React from 'react';
import {useLoaderData} from "react-router-dom";
import {getOferta} from "../api/api";
import sendErrorToBot from '../api/sendError';
import ErrorPage from "./ErrorPage";
import '../styles/OfertaPage.css';

export async function ofertaLoader() {
    const url = new URL(window.location.href);
    const ofertaKey = url.searchParams.get('key');
    const response = await getOferta(ofertaKey || '');
    if (!response || !response.result || !response.result.text || !!response.error) {
        sendErrorToBot('🟡FOREIGN CARD REGISTRATION OFERTA',
            window.location.href + `${response && response.error ? `\nError: ${JSON.stringify(response.error)}` : ''}`
            + `${navigator && navigator.userAgent ? `\n📱Device: ${navigator.userAgent}` : ''}`);
        return { error: true };
    }
    return { oferta: response.result};
}

function OfertaPage() {
    const loaderData = useLoaderData();
    const isError = !!(loaderData && (loaderData as any).error);
    if (isError) {
        return ErrorPage();
    }

    return (
        <div className='oferta'>
            {loaderData && (loaderData as any).oferta && (loaderData as any).oferta.text
                && <div style={{padding: 18}} dangerouslySetInnerHTML={{__html: (loaderData as any).oferta.text}}/>}
        </div>
    );
}

export default OfertaPage;
